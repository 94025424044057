import React, { useState, useEffect, useRef } from 'react';
import { Steps, Col, Form } from 'antd';
import { setWizardForm, setStep, cleanWizardForm } from '../../redux/actions/wizardFormActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { cleanNotifications, sendNotification } from 'helpers/helperNotifications';
import { useTranslation } from 'react-i18next';

const { Step } = Steps;
function WizardFormExternal(props) {
  const {
    currentProfile,
    currentRol,
    dataToPage,
    disabledStep,
    enableInputs,
    disableInputs,
    formsExternal,
    isAttachment,
    isClickEnable,
    readOnly,
    withRef,
    cleanInitialForms,
    initialStep,
    userLock,
    wizard,
    propsExtras,
    isFromExternalBuyer,
    hasBeenRegistered
  } = props;
  const [getDataForm, setDataForm] = useState(false);
  const [InitialData, setInitialData] = useState(false);
  const [dismountData, setDismountData] = useState(null);
  const {t} = useTranslation();
  
  const dispatch = useDispatch();
  const refForm = useRef();
  const { forms: formsWizard } = wizard;
  let { currentStep } = wizard;
  if (initialStep !== 0) {
    currentStep = initialStep;
  }
  const next = () => {
    const current = Number(currentStep + 1);
    dispatch(setStep(current));
  };

  const prev = () => {
    const current = Number(currentStep - 1);
    dispatch(setStep(current));
  };

  //limpiamos los forms
  useEffect(() => {
    if (isFromExternalBuyer) {
      if (hasBeenRegistered) {
        if (cleanInitialForms) {
          dispatch(cleanWizardForm());
        }
      }
    } else {
      if (cleanInitialForms) {
        dispatch(cleanWizardForm());
      }
    }
  }, [dispatch, cleanInitialForms, isFromExternalBuyer, hasBeenRegistered]);

  //seteamos información desde el store si existiera
  useEffect(() => {
    let mounted = true;
    const existDataForm = () => {
      if (typeof formsWizard !== 'undefined') {
        if (typeof formsWizard[`Step${currentStep}`] !== 'undefined') {
          let form = formsWizard[`Step${currentStep}`];
          let extractInfo = Object.keys(form);
          let fields = {};
          extractInfo.forEach(k => {
            let currentKey = form[`${k}`];
            if (typeof currentKey !== 'undefined') {
              Object.assign(fields, { [`${k}`]: currentKey });
            }
          });
          if (isAttachment) {
            let transform = Object.values(fields);
            let setPrevious = {};
            transform.map(d => {
              if (d && typeof d.metadata !== 'undefined') {
                let me = Object.values(d.metadata);
                let metada = {};

                me.map(m => {
                  if (m) {
                    metada = { ...metada, [`${m.id}`]: m };
                  }
                  return null;
                });
                d.metadata = metada;
              }

              if (d) setPrevious = { ...setPrevious, [`${d.documentTypeId}`]: d };

              return null;
            });
            if (mounted) {
              let doc = { documentsObservations: fields.documentsObservations };
              setDataForm({ ...setPrevious, ...doc });
            }
          } else {
            let aux = fields;
            if (Object.keys(fields).length === 1) {
              let key = Object.keys(fields);
              aux = { ...fields[key] };
            }

            if (mounted) setDataForm(aux);
          }
        } else {
          if (mounted) setDataForm(false);
        }
      }
    };
    existDataForm();
    return () => {
      mounted = false;
    };
  }, [formsWizard, currentStep, isAttachment]);


  useEffect(() => {

    if(formsWizard && typeof formsWizard !== undefined && !InitialData){
      let newObj = {};
      let dataWizard = {};
      let { forms, userData, ...rest } = wizard;
      Object.keys(formsWizard).forEach(key => {
        if(Object.keys(formsWizard[key]).length > 0){
            let stepSlice = key.match(/(\d+)/);
            let parsedStep = Number(stepSlice[0]);
            if(Object.keys(forms).includes("Step4")){
              if(parsedStep === 1){
                newObj["address"] = formsWizard[key];
              }else{
                newObj = {...newObj, ...formsWizard[key]}
              }
            } else if(Object.keys(forms).includes("Step6")){
              if(parsedStep === 0){
                newObj["vehicleInfo"] = formsWizard[key];
              }else if(parsedStep === 1){
                newObj["locationInfo"] = formsWizard[key];
              }else if(parsedStep === 2){
                newObj["wreckVehicleInfo"] = formsWizard[key];
              }
            }
      }
      });

      dataWizard = { ...rest };
      dataWizard.userData = { ...userData, ...newObj };
      dataWizard.forms = { ...forms};
      dispatch(setWizardForm(dataWizard));
      setInitialData(true);
    }
    return ()=>{
    }
  }, [formsWizard, currentStep])

  useEffect(() => {
    if(dismountData && dismountData.data && dismountData.step >= 0){
      let objToSend = {};

      if(dismountData?.formType && dismountData.formType.length){

        switch (dismountData.formType) {
          case "adminForm":
          case 'insuranceForm':
          case 'moralBuyer':
          case 'phisicalBuyer':
          case 'providersForm':
          case 'providerForm':
                objToSend = { ...dismountData.data };
            break;
        }

      }else{
        if(Object.keys(wizard.forms).includes("Step4")){
          console.log("dismountData1", dismountData);
          switch (dismountData.step) {
            case 1:
              objToSend["address"] = dismountData.data;
              break;
            default:
              objToSend = {... objToSend, ...dismountData.data}
              break;
          }
        }else if(Object.keys(wizard.forms).includes("Step6")){
          switch (dismountData.step) {
            case 0:
                objToSend = { vehicleInfo: dismountData.data};
                if(wizard && wizard.forms && wizard.forms[`Step${dismountData.step}`]){
                  objToSend.vehicleInfo.caseId = wizard.forms[`Step${dismountData.step}`].caseId;
                  objToSend.vehicleInfo.caseStatusId = wizard.forms[`Step${dismountData.step}`].caseStatusId;
                }
              break;
            case 1:
                objToSend = { locationInfo: dismountData.data};
              break;
            case 2:
                objToSend = { wreckVehicleInfo: dismountData.data};
              break;
            default:
              break;
          }
        }
      }

      let dataWizard = null;
      let { forms, userData, ...rest } = wizard;
      dataWizard = { ...rest };
      dataWizard.userData = { ...userData, ...objToSend };
      dataWizard.forms = { ...forms, [`Step${dismountData.step}`]: dismountData.data };
      dispatch(setWizardForm(dataWizard));
      setDismountData(null);
    };
  }, [currentStep, dismountData])
  
  

  const  onChange = async(current) => {
    if(withRef && !readOnly){
      const form = refForm?.current?.getForm();
      if(form){
        form.validateFields(async (err, values) => {
          if(!err){
            dispatch(setStep(current));
          }else{
            //do not validate on publication cases
            if(currentStep === 5 && values?.managementCost === "" && values?.comments === ""){
              dispatch(setStep(current));
            }else if(Object.keys(err).length > 0){
              cleanNotifications();
              const firstErrorKey = Object.keys(err)[0];
              const errorsFirstKey = err && err[firstErrorKey] && err[firstErrorKey].errors.length ? err[firstErrorKey].errors[0] : null;
              sendNotification('error', errorsFirstKey ? errorsFirstKey.message : t('system.missingrequiredfields'));
            }
          }
        });
        return;
      }else{
        dispatch(setStep(current));
      }
    }else{
      dispatch(setStep(current));
    }
  };

  const onDismount = (data, step, formType) =>{
    setDismountData({data, step, formType});
  }

  const formToState = (data, step, lastStep, type, attachments, isLock, noAttachment = false) => {
    let finalStep = typeof lastStep !== 'undefined' ? lastStep : false;
    let isAttachmentForm = typeof attachments !== 'undefined' ? lastStep : false;
    let dataWizard = null;
    let userIsLock = typeof isLock !== 'undefined' && isLock ? true : false;

    if (typeof wizard.forms === 'undefined') {
      dataWizard = {
        name: 'addUser',
        rol: currentRol,
        profile: currentProfile,
        forms: { [`Step${step}`]: data },
        userData: { ...data }
      };
    } else {
      let { forms, userData, ...rest } = wizard;
      dataWizard = { ...rest };
      dataWizard.userData = { ...userData, ...data };
      dataWizard.forms = { ...forms, [`Step${step}`]: data };
      dataWizard.userIsLock = userIsLock;
    }

    if(dataWizard && dataWizard.userData && dataWizard.userData.workInfo && dataWizard.userData.workInfo.workInfo){
      dataWizard.userData.workInfo = dataWizard.userData.workInfo.workInfo;
    }

    dispatch(setWizardForm(dataWizard));
    if (finalStep && (!isAttachmentForm || noAttachment)) {
      props.dataToPage(dataWizard.userData, type);
    }
    if (finalStep && (isAttachmentForm && !noAttachment)) {
      props.dataToPage(dataWizard.forms);
    }
  };

  const createClonComponent = st => {
    let child = {};
    let isWithRef = withRef ? { ref: refForm } : {};

    if (formsExternal[st]) {
      child = formsExternal[st].content;
    } else {
      child = formsExternal[0].content;
    }

    return React.cloneElement(child, {
      dataToPage: dataToPage,
      enableInputs: enableInputs,
      disableInputs: disableInputs,
      formToState: formToState,
      onDismount : onDismount,
      ...isWithRef,
      next: next,
      onChangeClickEnable: currentStep,
      prev: prev,
      previousForm: getDataForm,
      userIsLock: userLock,
      readOnly: readOnly,
      stepCu: st,
      ...child.props,
      ...propsExtras
    });
  };

  if (typeof formsExternal !== 'undefined' && formsExternal.length > 0 && currentStep !== null) {
    let step = typeof currentStep !== 'undefined' ? currentStep : 0;

    const stepIsClickEnable = isClickEnable ? { onChange: onChange } : '';

    return (
      <React.Fragment>
        <Steps type="navigation" size="small" current={step} {...stepIsClickEnable} >
          {formsExternal.map(form => {
            return (
              <Step disabled={disabledStep} key={form.title} title={form.title} icon={form.icon} />
            );
          })}
        </Steps>

        <Col span={24} className="container-wizard-forms text-right">
          <div className="steps-content">
            {/* <Suspense fallback={<Loader />}> */}
            <React.Fragment>{createClonComponent(step)}</React.Fragment>
            {/* </Suspense> */}
          </div>
        </Col>
      </React.Fragment>
    );
  } else {
    return <h2>No existe la opción asignada</h2>;
  }
}

function mapStateToProps(state) {
  return { wizard: state.wizardForm, userLock: state.wizardForm.userIsLock };
}

export default connect(mapStateToProps, {
  setStep
})(WizardFormExternal);

WizardFormExternal.propTypes = {
  cleanInitialForms: PropTypes.bool,
  initialStep: PropTypes.number,
  propsExtras: PropTypes.object,
  isFromExternalBuyer: PropTypes,
  hasBeenRegistered: PropTypes.bool,
  wizard : PropTypes.object
};

WizardFormExternal.defaultProps = {
  cleanInitialForms: true,
  initialStep: 0,
  propsExtras: {},
  isFromExternalBuyer: false,
  hasBeenRegistered: false,
  wizard : {}
};
